
import { defineComponent, onMounted, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import {
  useSites,
  useDeleteSite
} from '@/composables/api';
import { SiteIdOption } from '@/services/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  components: {
    SearchBox,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const startDate = ref();
    const endDate = ref();
    const dateRange = ref([]);
    const sortBy = ref();
    const descSortBy = ref('updated_at');
    const route = useRoute();

    const { data, isLoading, isFetching, refetch } = useSites({ page, keyword, startDate, endDate, sortBy, descSortBy });
    const { isLoading: isDeletedLoading, mutate } = useDeleteSite();

    const statusIndex = ref(undefined);

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const setDateRange = () => {
      startDate.value = dateRange.value[0];
      endDate.value = dateRange.value[1];
    };

    const deleteSite = ({ siteId }: SiteIdOption, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm(
        'Are you sure you want to delete?',
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => {
          mutate({ siteId }, {
            onSuccess() {
              refetch.value();

              ElMessage({
                type: 'success',
                message: 'Delete successfully'
              });
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
            }
          });
        })
        .catch();
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    return {
      page,
      isLoading,
      isFetching,
      data,
      isDeletedLoading,
      deleteSite,
      sortChange,
      statusIndex,
      searchKeyword,
      dateRange,
      setDateRange
    };
  }
});
